import React from "react"
import { MobileOrderSumaryContainer, OrderSumaryContainer, OrderSummaryItem, OrderSummaryTitle, OrderSummaryTotalsContainer, OrderSummaryWrapper, PlateSubTotal } from "./OrderSummary.styles"
import { Grid, Divider } from "semantic-ui-react"
import { Legal } from "@styles/Global.styles"
import { AfterpayMessage } from "@utils/Afterpay"
import { PaymentOptions } from "@utils/Constant"
import { getGiftCardNameFromType, getGiftCardPriceFromType, isMobile } from "@utils/Helpers"
import { getCurrentBasket } from "@redux/localStorage/basket"
import { getCurrentCheckout } from "@redux/localStorage/checkout"
import { CombinationPaymentList } from "@components/CombinationPricingOptions/CombinationPaymentList"

export type OrderTotalProps = {
    paymentOption: number,
    total: number,
    laybyDeposit?: number
}

export const OrderTotal = (paymentProps: OrderTotalProps) => {
    const { total, laybyDeposit, paymentOption } = paymentProps;
    const showSurcharge = paymentOption == PaymentOptions.KiwiplatesLayby || paymentOption == PaymentOptions.CreditCard;
    const basket = getCurrentBasket()
    const checkout = getCurrentCheckout()
    return (
        <React.Fragment>
        {isMobile() ?
        <MobileOrderSumaryContainer>
            <OrderSummaryWrapper>
                <Grid>
                    {
                        paymentOption == PaymentOptions.KiwiplatesLayby && laybyDeposit ?
                            <Grid.Row>
                                <Grid.Column width={8} textAlign='left'>
                                    <OrderSummaryItem>Layby Total</OrderSummaryItem>
                                </Grid.Column>
                                <Grid.Column width={8} textAlign='right'>
                                    <OrderSummaryItem>${(total * 1.019).toFixed(2)}</OrderSummaryItem>
                                </Grid.Column>
                                <Grid.Column width={8} textAlign='left'>
                                    <OrderSummaryTitle>Due Today</OrderSummaryTitle>
                                </Grid.Column>
                                <Grid.Column width={8} textAlign='right'>
                                    <OrderSummaryTitle>${(laybyDeposit * 1.019).toFixed(2)}</OrderSummaryTitle>
                                </Grid.Column>
                            </Grid.Row>
                            :
                            <Grid.Row>
                                <Grid.Column width={8} textAlign='left'>
                                    <OrderSummaryTitle>Total (incl. GST)</OrderSummaryTitle>
                                </Grid.Column>
                                <Grid.Column width={8} textAlign='right'>
                                    <OrderSummaryTitle>${showSurcharge ? (total * 1.019).toFixed(2) : total}</OrderSummaryTitle>
                                </Grid.Column>
                            </Grid.Row>
                    }
                    {
                        paymentOption == PaymentOptions.Afterpay &&
                        <>
                            <Grid.Row>
                                <Grid.Column width={16} textAlign='right'>
                                    <CombinationPaymentList basePrice={total}/>
                                </Grid.Column>
                            </Grid.Row>
                        </>
                    }
                    {
                        showSurcharge &&
                        <Grid.Row>
                            <Grid.Column width={16} textAlign='right'>
                                <Legal color='#b7b7b7'>Includes {(total * 0.019).toFixed(2)} Card Payment Surcharge</Legal>
                            </Grid.Column>
                        </Grid.Row>
                    }
                </Grid>
            </OrderSummaryWrapper>
        </MobileOrderSumaryContainer> :
        <OrderSumaryContainer>
            <OrderSummaryWrapper>
                <Grid>
                {
                    basket.basketLines.length == 1 && checkout && checkout?.giftingType > 0 &&
                  <>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={8} textAlign='left'>
                                <PlateSubTotal>Plate Total</PlateSubTotal>
                            </Grid.Column>
                            <Grid.Column width={8} textAlign='right'>
                                <PlateSubTotal>${(total * 1.019).toFixed(2)}</PlateSubTotal>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <OrderSummaryTotalsContainer>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16}>{getGiftCardNameFromType(checkout.giftingType)}</Grid.Column>
                                <Grid.Column width={16}><strong>To: </strong><span>{checkout.giftingDetails.to}</span></Grid.Column>
                                <Grid.Column width={16}><strong>From: </strong><span>{checkout.giftingDetails.from}</span></Grid.Column>
                                <Grid.Column width={16}><strong>Message: </strong><span style={{wordWrap:'break-word'}}>{checkout.giftingDetails.message}</span></Grid.Column>
                                <Grid.Column width={8}><strong>{getGiftCardNameFromType(checkout.giftingType)} total</strong></Grid.Column>
                                <Grid.Column width={8}><strong style={{float: 'right'}}>{getGiftCardPriceFromType(checkout.giftingType)}</strong></Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </OrderSummaryTotalsContainer>
                  </>
                }
                    {
                        paymentOption == PaymentOptions.KiwiplatesLayby && laybyDeposit ?
                            <Grid.Row>
                                <Grid.Column width={8} textAlign='left'>
                                    <OrderSummaryItem>Layby Total</OrderSummaryItem>
                                </Grid.Column>
                                <Grid.Column width={8} textAlign='right'>
                                    <OrderSummaryItem>${(total * 1.019).toFixed(2)}</OrderSummaryItem>
                                </Grid.Column>
                                <Grid.Column width={8} textAlign='left'>
                                    <OrderSummaryTitle>Due Today</OrderSummaryTitle>
                                </Grid.Column>
                                <Grid.Column width={8} textAlign='right'>
                                    <OrderSummaryTitle>${(laybyDeposit * 1.019).toFixed(2)}</OrderSummaryTitle>
                                </Grid.Column>
                            </Grid.Row>
                            :
                            <Grid.Row>
                                <Grid.Column width={8} textAlign='left'>
                                    <OrderSummaryTitle>Total</OrderSummaryTitle>
                                </Grid.Column>
                                <Grid.Column width={8} textAlign='right'>
                                    <OrderSummaryTitle>${showSurcharge ? (total * 1.019).toFixed(2) : total}</OrderSummaryTitle>
                                </Grid.Column>
                            </Grid.Row>
                    }
                    {
                        paymentOption == PaymentOptions.Afterpay &&
                        <>
                            <Divider />
                            <Grid.Row>
                                <Grid.Column width={16} textAlign='right'>
                                    <CombinationPaymentList basePrice={total}/>
                                </Grid.Column>
                            </Grid.Row>
                        </>
                    }
                    {
                        showSurcharge &&
                        <Grid.Row>
                            <Grid.Column width={16} textAlign='right'>
                                <Legal color='#b7b7b7'>Includes {(total * 0.019).toFixed(2)} Card Payment Surcharge</Legal>
                            </Grid.Column>
                        </Grid.Row>
                    }
                </Grid>
            </OrderSummaryWrapper>
        </OrderSumaryContainer>}
        </React.Fragment>
    )
}